window.addEventListener('load', () => {
    try {
        jQuery('.cep-debut').mask('00000-000');
    } catch(ex) {
        console.log('Failed applying mask')
    }

    const body = document.querySelector('body');
    const form = document.querySelector('form.wpcf7-form');
    const divFormOutput = document.querySelector('.cobertura .wpcf7-response-output');
    if(typeof wpcf7 !== 'undefined') wpcf7.submit(form);

    const wrapForm = document.querySelector('.cobertura__form-wrap');
    const wrapSemCobertura = document.querySelector('#coberturaResultadoSemViabilidade');
    const wrapComCobertura = document.querySelector('#coberturaResultadoComViabilidade');


    const inputCep = form.querySelector('input[name="cep"]');
    const inputCelular = form.querySelector('input[name="celular"]')
    const inputHouseNumber = form.querySelector('input[name="numero"]')
    const inputCobertura = document.querySelector('.roof-input');
    const inputRua = document.querySelector('.roof-street');
    const inputBairro = document.querySelector('.roof-district');
    const inputCidade = document.querySelector('.roof-city');
    const inputUFRoof = document.querySelector('.roof-state');

    const buttonConsultar = form.querySelector('a[class$="fake-submit"]');


    document.querySelectorAll('.btn-reset').forEach(el => {
        el.addEventListener('click', () => {
            formResetFeedback();
            wrapForm.style.display = 'block';
            wrapComCobertura.style.display = 'none';
            wrapSemCobertura.style.display = 'none';
        });
    })

    const hasClass = (elem, classname) => (elem.classList.contains(classname));
    const classListAdd = (elem, classes) => (Array.isArray(classes) ? classes.forEach(_class => elem.classList.add(_class)) : elem.classList.add(classes));
    const classListRemove  = (elem, classes) => (Array.isArray(classes) ? classes.forEach(_class => elem.classList.remove(_class)) : elem.classList.remove(classes));

    const formInvalidFeedback = (text, input=null) => {
        classListAdd(form, 'invalid');
        classListAdd(form, 'cep-invalid');
        if(input) classListAdd(input, 'wpcf7-not-valid');
        divFormOutput.innerHTML = text;
    }

    const formResetFeedback = () => {
        form.querySelectorAll('input').forEach(input => classListRemove(input, 'wpcf7-not-valid'));
        classListRemove(form, 'invalid');
        classListRemove(form, 'cep-invalid');
        divFormOutput.innerHTML = '';
    }

    const validateCEP = async () => {
        const cep = inputCep.value;
        if(!cep) return formInvalidFeedback('Por favor, informe o CEP do endereço.', inputCep);
        if(cep.length < 8) return formInvalidFeedback('Por favor, informe o CEP completo do endereço.', inputCep);

        const celular = inputCelular.value;
        if(!celular) return formInvalidFeedback('Por favor, informe um número de celular.', inputCelular);
        if(celular.length < 10) return formInvalidFeedback('Por favor, informe um número de celular válido.', inputCelular);

        try {
            const response = await fetch(`https://ws.apicep.com/cep/${cep}.json`)
            const json = await response.json();
            const valid = Boolean(json.status == 200);
            if(valid) {
                formResetFeedback();
                inputRua.value = json.address;
                inputBairro.value = json.district;
                inputCidade.value = json.city;
                inputUFRoof.value = json.state;
            } else {
                formInvalidFeedback('Por favor, digite um CEP válido.', inputCep);
            }
            inputCep.style.border = valid ? 'none' : '2px solid red';
            return valid;
        } catch(ex) {
            return false;
        }
    }

    const verifyViability = async () => {
        const zipCode = inputCep.value.replace(/\D/g, "");
        const phone = inputCelular.value.replace(/\D/g, "");
        const number = inputHouseNumber.value;
        if(!zipCode) return false;

        const viabiliade_response = await sendForm(phone, zipCode, number);
        if (!viabiliade_response.disponibilidade && viabiliade_response.exception === 'Número de telefone inválido') {
            formInvalidFeedback('Por favor, informe um número de celular válido.', inputCelular);
            return false;
        }
        wrapForm.style.display = 'none';
        if(viabiliade_response.disponibilidade) {
            wrapComCobertura.style.display = 'flex';
            // const nova_rul = `https://checkoutnew.verointernetfibra.com.br/?by_pass=true&postal_code=${zipCode}&number=${number}&utm_campaign=vero&phone_number=${phone}`;
            // window.open(nova_rul, '_blank');
        } else {
            wrapSemCobertura.style.display = 'flex';
        }
    }

    const sendForm = async (phone, zipCode, number) => {
        classListAdd(buttonConsultar, '-loading');
        try {
            const b2b = window.location.toString().includes('empresa');
            let baseUrl = window.location.origin;
            const url = `${baseUrl}/api2/public/viabilidade/consultar?b2b=${b2b}&contato=${phone}&cep=${zipCode}&numero=${number}`;
            const response = await fetch(url, {
                method: 'GET'
            });
            const response_json = await response.json();
            return response_json
        } catch(ex){
            return false;
        } finally {
            classListRemove(buttonConsultar, '-loading');
        }
    }

    const formReset = () => {
        inputRua.value =  '';
        inputBairro.value =  '';
        inputCidade.value =  '';
        inputUFRoof.value =  '';
        inputCobertura.value =  '';
        divFormOutput.innerHTML = '';
        divFormOutput.removeAttribute('style');
        classListRemove(form, ['sent', 'invalid', 'failed', 'spam', 'cep-invalid']);
        classListRemove(body, ['withour-roof', 'with-roof', 'wanna-contact', 'dont-contact', 'sent-roof']);
        document.querySelectorAll('.cobertura__btn').forEach(el => {
            el.disabled = false;
            classListRemove(el, '-loading')
        });
        formResetFeedback();
    }

    buttonConsultar.addEventListener('click', async () => {
        if(hasClass(buttonConsultar, '-loading')) return;

        if(hasClass(body, 'with-roof')) {
            classListAdd(body, '-is-question');
        } else {
            classListAdd(buttonConsultar, '-loading');
            if(!await validateCEP()) return classListRemove(buttonConsultar, '-loading');
            await verifyViability();
        }
    });


    document.querySelectorAll('.-roof-reset').forEach(el => {
        el.addEventListener('click', () => {
            formReset();
        });
    });
});